/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
    const { site } = useStaticQuery(
       graphql`
           query {
               site {
                   siteMetadata {
                       title
                       description
                       author
                   }
               }
           }
       `,
    )

    const metaDescription = description || site.siteMetadata.description

    return (
       <Helmet
          htmlAttributes={{
              lang,
          }}
          title={title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
              {
                  name: `description`,
                  content: metaDescription,
              },
              {
                  property: `og:title`,
                  content: title,
              },
              {
                  property: `og:description`,
                  content: metaDescription,
              },
              {
                  property: `og:type`,
                  content: `website`,
              },
              {
                  name: `twitter:card`,
                  content: `summary`,
              },
              {
                  name: `twitter:creator`,
                  content: site.siteMetadata.author,
              },
              {
                  name: `twitter:title`,
                  content: title,
              },
              {
                  name: `twitter:description`,
                  content: metaDescription,
              },
              {
                  name: `google-site-verification`,
                  content: "2c54MAfzik94v63ANxx5invclsQ-QZc6lX7BxSMRuQM",
              },
          ].concat(meta)}
       >
           <script type="application/ld+json">{`
               {
                   "@context": "https://schema.org/",
                   "@type": "WebSite",
                   "name": "Twitch Countdown",
                   "url": "https://twitch-countdown.com"
               }
           `}</script>
           {/*
           <script data-ad-client="ca-pub-5725037434022079" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

           <script type="text/javascript">{`
               var gaProperty = 'UA-5037623-30';
               var disableStr = 'ga-disable-' + gaProperty;
               if (document.cookie.indexOf(disableStr + '=true') > -1) {
               window[disableStr] = true;
           }
               function gaOptout() {
               document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
               window[disableStr] = true;
               alert('Das Tracking durch Google Analytics wurde in Ihrem Browser für diese Website deaktiviert.');
           }
           `}</script>
           */}
       </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: `Small tool to create an overlay browser-source with an countdown ticker to add to your twitch stream.`,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
