/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"

// import Header from "./header"
import "./layout.css"
import Sidebar from "./sidebar"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: lightBlue,
    },
});

const Layout = ({ children }) => {
    //
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const classes = useStyles()
    const [showSidebar, toggleSidebar] = useState(false)

    return (
       <ThemeProvider theme={theme}>
           <Sidebar show={showSidebar} onClose={() => toggleSidebar(!showSidebar)}/>           
               
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu"
                                onClick={() => toggleSidebar(!showSidebar)}>
                        <MenuIcon/>
                    </IconButton>

                    <Typography variant="h6" className={classes.title}>
                        <Link to="/" style={{ color: "white"}}>
                            {data.site.siteMetadata.title}
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>

           <main>

               <Grid
                  container
                  className={classes.root}
                  direction="row"
                  justify="center"
               >
                   <Grid item xs={12} md={8} lg={3}></Grid>

                   <Grid item md={12} lg={6}>
                       <Card className={classes.card}>
                           <CardContent>
                               {children}
                           </CardContent>
                       </Card>
                   </Grid>

                   <Grid item xs={12} md={8} lg={3}></Grid>

               </Grid>

           </main>


           <footer>
               &copy; {new Date().getFullYear()}
               {/*
               <ul className="list-inline">
                   <li><a href="https://denkfabrik-neueMedien.de/impressum" target="_blank"
                          rel="noopener noreferrer">Imprint</a></li>
                   <li><a href="https://denkfabrik-neueMedien.de/datenschutz" target="_blank"
                          rel="noopener noreferrer">Privacy</a></li>
               </ul>
               */}
           </footer>

       </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
