import React from "react"
import PropTypes from "prop-types"

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AvTimerRoundedIcon from '@material-ui/icons/AvTimerRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
// import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';


export default class Sidebar extends React.PureComponent {

    pages() {
        return [
            {
                name: "Home",
                link: "/",
                icon: <HomeRoundedIcon />
            },
            {
                name: "Countdown from (hh:mm:ss)",
                link: "/countdown-from",
                icon: <AvTimerRoundedIcon />
            },
            {
                name: "Countdown to (Date & Time)",
                link: "/countdown-to",
                icon: <UpdateRoundedIcon />
            }
            /*,
            {
                name: "Help",
                link: "help",
                icon: <HelpRoundedIcon />
            },*/
        ];
    }

    metaPages() {
        return [
            {
                name: "News",
                link: "/news",
                icon: <AnnouncementRoundedIcon />
            },
            {
                name: "Imprint",
                link: "/imprint",
                icon: <DescriptionRoundedIcon />
            },
            {
                name: "Privacy",
                link: "/privacy",
                icon: <VerifiedUserRoundedIcon />
            },
        ];
    }


    render() {

        const { show, onClose } = this.props;

        return (
           <Drawer open={show} onClose={onClose}>
               <List>
                   {this.pages().map(({ name, link, icon}, index) => (
                      <ListItem button component="a" key={name} href={link}>
                          <ListItemIcon>
                              {icon}
                          </ListItemIcon>
                          <ListItemText primary={name} />
                      </ListItem>
                   ))}
               </List>
               <Divider />
               <List>
                   {this.metaPages().map(({ name, link, icon }, index) => (
                      <ListItem button component="a" key={name} href={link}>
                          <ListItemIcon>
                              {icon}
                          </ListItemIcon>
                          <ListItemText primary={name} />
                      </ListItem>
                   ))}
               </List>
           </Drawer>
        )
    }
}


Sidebar.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}


Sidebar.defaultProps = {
    show: false,
}